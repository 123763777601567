@import '_bootstrap_variables.scss';
@import 'vars-colors.scss';
@import 'mix-fonts.scss';

html {
	min-height: 100vh;
}

body {
	margin: 0px;
	padding: 96px 0px 0px 0px;
	background-color: #edeff1;
	@include font-main;
	@include fs-12;
	line-height: 1.5;
	min-height: 100vh;

	&.profile {
		padding: 0;
	}
	&.bg-white {
		background-color: $white;
	}
}

body.modal-open {
	padding-right:0px !important;
}

h1, h2 {
	@include font-head;
	color: $primary-dark;
}

h1 {
	@include fs-40;
	line-height: 1.18;
	.pagetitle {
		font-size: 22px;
		line-height: 1.36;
	}
	@media (max-width: 767px) {
		line-height: 1;
		@include fs-28;
	}
}

h2 {
	margin-bottom:20px;
	@include fs-22;
	sub {
		bottom:0;
		@include fs-13;
	}
}

a {
	color: var(--bs-primary);
	&:hover {
		color: $secondary-color;
	}
}

strong {
	@include fw-600;
}

/*
@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
 	font-display: block;
	src: url(https://img1.one.bid/fonts/MaterialIcons-Regular.eot);
	src: url(https://img1.one.bid/fonts/MaterialIcons-Regular.woff2) format('woff2'),
	url(https://img1.one.bid/fonts/MaterialIcons-Regular.woff) format('woff'),
	url(https://img1.one.bid/fonts/MaterialIcons-Regular.ttf) format('truetype');
}
*/



// Bootstrap 5 settings
.alert-success {
	color: #fff;
	background-color: var(--bs-success);
}
.alert-primary {
	color: #fff;
	background-color: var(--bs-primary);
}
.alert-secondary {
	color: #fff;
	background-color: var(--bs-secondary);
}
.alert-danger {
	color: #fff;
	background-color: var(--bs-danger);
}
.alert-warning {
	color: #fff;
	background-color: var(--bs-warning);
}
.alert-info {
	color: #fff;
	background-color: var(--bs-info);
}
.alert-dark {
	color: #fff;
	background-color: #212529;
}

.bg-silver {background: #e6e6e6;}

.btn-primary {
	color: #fff;
	&:hover {
		color: #fff;
	}
}
.btn-secondary {
	color: #fff;
	&:hover {
		color: #fff;
	}
}
.btn-success {
	color: #fff;
	&:hover {
		color: #fff;
	}
}
.btn-info {
	color: #fff;
	&:hover {
		color: #fff;
	}
}
.btn-warning {
	color: #fff;
	&:hover {
		color: #fff;
	}
}
.btn-danger {
	color: #fff;
	&:hover {
		color: #fff;
	}
}
.btn-outline-primary {
	&:hover {
		color: #fff;
	}
}
.btn-outline-secondary {
	&:hover {
		color: #fff;
	}
}
.btn-outline-success {
	&:hover {
		color: #fff;
	}
}
.btn-outline-info {
	&:hover {
		color: #fff;
	}
}
.btn-outline-warning {
	&:hover {
		color: #fff;
	}
}
.btn-outline-danger {
	&:hover {
		color: #fff;
	}
}
.btn-link {
	&:hover {
		text-decoration: underline !important;
	}
}

.modal-blur {
	backdrop-filter: blur(10px);
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 1 !important;
}





.w-5 {width: 5%!important;}
.w-10 {width: 10%!important;}
.w-15 {width: 15%!important;}
.w-20 {width: 20%!important;}
.w-30 {width: 30%!important;}
.w-40 {width: 40%!important;}
.w-60 {width: 60%!important;}
.w-65 {width: 65%!important;}
.w-70 {width: 70%!important;}
.w-75 {width: 75%!important;}

.cutlongnames {overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
.pointer {
	&:hover {
		cursor: pointer;
	}
}

.container-fluid {
	max-width:1800px;
}

.main-margin {
  margin: 0 15px;
}

.main-padding {
	padding-left: 24px !important;
	padding-right: 24px !important;
}

.text-justify {
	text-align: justify;
}

.lh-normal {line-height: normal;}

.statblock {
  margin-bottom:30px;

  .statcircle {
    padding-bottom: 14px;
    padding-top: 30px;

    span {
      width: 60px;
      height: 60px;
      line-height:60px;
      background-color: rgba($primary-color,0.05);
      color:$primary-color;
      @include font-icon;
      font-size:25px;

      &.statgreen {
        background-color: rgba($notify-green,0.05);
        color:$notify-green;
      }

      &.statblue {
        background-color: rgba($notify-blue,0.05);
        color:$notify-blue;
      }
    }

    .statcounter {
      color: $primary-dark;
      @include font-head;
      @include fs-50;
    }

    em {
      color: $gray-dark;
      @include fs-14;
      font-style: normal;
    }
  }

}

@media (max-width:768px) {

  .main-margin-top {

      margin-top: 20px;
  }

} // @media (max-width:768px) EO

@media screen and (min-width:1400px) {
	
	.leftmainmenu  {
		flex: 0 0 20%;
		max-width:20%;
	}
	
	.centermainpage {
		flex: 0 0 80%;
		max-width:80%;
	}
	
}

.mdc-switch {
	margin-right: 20px;
	
	&.mdc-switch--checked {
		.mdc-switch__track {
			background-color: $primary-color !important;
			color: $primary-color !important;
		}
	}
}

.flash {
	color: $white;
	
	&.flash-success {
		background-color: $notify-green;
	}
	
	&.flash-error {
		background-color: $notify-red;
	}
	
	&.flash-warning {
		background-color: $notify-yellow;
	}
	&.flash-notice {
		background-color: $notify-blue;
	}
	
}

.two-columns {
	
	.carouselbox {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		width: 292px;
		height: 310px;
		margin: 0;
		-webkit-perspective: 800px;
		perspective: 800px;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	
	.carouselbox-background {
		background-position: center;
		background-size: cover;
		width:100%;
		height: 100%;
		position: absolute;
		top:0;
		left:0;
		z-index:1;
		opacity: 0.2;
		background-size: 150%;
	}
	
	.carouselbox-content {
		position: absolute;
		width: 100%;
		height: 100%;
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
		-webkit-transform: translateZ(-382px) rotateY(0);
		transform: translateZ(-382px) rotateY(0);
		-webkit-animation: carouselbox 10s infinite cubic-bezier(1, 0.015, 0.295, 1.225) forwards;
		animation: carouselbox 10s infinite cubic-bezier(1, 0.015, 0.295, 1.225) forwards;
	}
	
	.carouselbox-item {
		position: absolute;
		top: 0;
		left: 0;
		width: 292px;
		height: 310px;
		border-radius: 6px;
		background-color: #fff;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		border: 1px solid #ddd;
		-webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.20);
		-moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.20);
		box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.20);
	}
	
	.carouselbox-item:nth-child(1) {
		-webkit-transform: rotateY(0) translateZ(282px);
		transform: rotateY(0) translateZ(282px);
	}
	
	.carouselbox-item:nth-child(2) {
		-webkit-transform: rotateY(120deg) translateZ(282px);
		transform: rotateY(120deg) translateZ(282px);
	}
	
	.carouselbox-item:nth-child(3) {
		-webkit-transform: rotateY(240deg) translateZ(282px);
		transform: rotateY(240deg) translateZ(282px);
	}
	
	@-webkit-keyframes carouselbox {
		0%, 17.5% {
			-webkit-transform: translateZ(-282px) rotateY(0);
			transform: translateZ(-282px) rotateY(0);
		}
		27.5%, 45% {
			-webkit-transform: translateZ(-282px) rotateY(-120deg);
			transform: translateZ(-282px) rotateY(-120deg);
		}
		55%, 72.5% {
			-webkit-transform: translateZ(-282px) rotateY(-240deg);
			transform: translateZ(-282px) rotateY(-240deg);
		}
		82.5%, 100% {
			-webkit-transform: translateZ(-282px) rotateY(-360deg);
			transform: translateZ(-282px) rotateY(-360deg);
		}
	}
	@keyframes carouselbox {
		0%, 17.5% {
			-webkit-transform: translateZ(-282px) rotateY(0);
			transform: translateZ(-282px) rotateY(0);
		}
		27.5%, 45% {
			-webkit-transform: translateZ(-282px) rotateY(-120deg);
			transform: translateZ(-282px) rotateY(-120deg);
		}
		55%, 72.5% {
			-webkit-transform: translateZ(-282px) rotateY(-240deg);
			transform: translateZ(-282px) rotateY(-240deg);
		}
		82.5%, 100% {
			-webkit-transform: translateZ(-282px) rotateY(-360deg);
			transform: translateZ(-282px) rotateY(-360deg);
		}
	}
	
}

#companyindexpage {
	
	.auctiondetails {
		
		@media(min-width: 768px) {
			border-left: 1px solid #ddd;
		}

	}
	
}

.fs-150p {font-size: 150%;}
.fs-200p {font-size: 200%;}
.fs-250p {font-size: 250%;}
.fs-300p {font-size: 300%;}

input.noarrows::-webkit-outer-spin-button,
input.noarrows::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input.noarrows[type=number] {
	-moz-appearance: textfield;
}

.spin {
	animation-name: spin;
	animation-duration: 2000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear; 
}
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.blink {
	animation-name: blink;
	animation-duration: 2500ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear; 
}
@keyframes blink {
	50% {opacity: 0.5;}
}


#mainpreloader {
	display: none;
}

.preloaderbackground {
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	position: fixed;
	z-index: 10000;
	opacity: 0.8;
	background: #848484;
}
.preloaderlogo {
	.spin {
		position: absolute;
		top: calc(50% - 25px);
		left: calc(50% - 25px);
		width: 50px;
		height: 50px;
		border-radius: 50%;
		border:4px solid #282a2c;
		border-top: 4px solid #e2aa12;
		border-bottom: 4px solid #e2aa12;
		animation: preloaderspin 1s infinite linear;
		-webkit-animation: preloaderspin 1s infinite linear;
		-moz-animation: preloaderspin 1s infinite linear;
		-o-animation: preloaderspin 1s infinite linear;
		-ms-animation: preloaderspin 1s infinite linear;
		
		@keyframes preloaderspin {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
		@-webkit-keyframes preloaderspin {
			0% {
				-webkit-transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
			}
		}
		@-moz-keyframes preloaderspin {
			0% {
				-moz-transform: rotate(0deg);
			}
			100% {
				-moz-transform: rotate(360deg);
			}
		}
		@-o-keyframes preloaderspin {
			0% {
				-o-transform: rotate(0deg);
			}
			100% {
				-o-transform: rotate(360deg);
			}
		}
		@-ms-keyframes preloaderspin {
			0% {
				-ms-transform: rotate(0deg);
			}
			100% {
				-ms-transform: rotate(360deg);
			}
		}
		
		.logo {
			border-radius: 50%;
			width: 34px;
			height: 34px;
			margin-top: 4px;
			margin-left: 4px;
			text-align: center;
			background: #282a2c;
			animation: preloaderspinoff .7s infinite linear;
			-webkit-animation: preloaderspinoff .7s infinite linear;
			-moz-animation: preloaderspinoff .7s infinite linear;
			-o-animation: preloaderspinoff .7s infinite linear;
			-ms-animation: preloaderspinoff .7s infinite linear;
			
			.hammer {
				display: block;
				width: 30px;
				height: 30px;
				background-repeat: no-repeat;
				background-attachment: fixed;
				background-position: 50% 50%;
				background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA+wD7APtVWW2WAAAACXBIWXMAAA3XAAAN1wFCKJt4AAAAB3RJTUUH5AIWFiQVyi1ymAAAAgZJREFUSMftlLFrU2EUxX9fikOCYvOi6RDzBsEl4GDFSZRGCrrYQc1rEcRJFxu61eKkBf+AWgqiDlJB8GWRCqKbGURBwUnMUFFeHiiSPlurgSr2uDzwGZImwXQRz3g43PN995574Z+GpFFJBzer+LB+46ak7b02OCJpLWLyQdKpnhl4rpWWlJP0TH9iQVL2b4vv8Vzrm+da1/z7R7dJKkpajZisSjrRSa1YI1GZtwCuAwmguP79xetqKfUOyAEPQtlWYKQTA9Pk9WeB2020JaCYLSwdAs4AE8aY910ZeK61E6gAVgv9MnDAdoLFTtvd2KLzGxQHeFVb/rnYzTxjYSTzkq5mC7VZ4Byw0kLv7ujvS3djYCTFgBqQBD4C49VS6ikwC0Rz/xAYBrYAT4BJ2wletjWo1+vE4/EycDjCLwAXqqXUIDAX9n4QmAamIrq7wJTtBNWWLUokEgBDwATwNeRHgDfZwlLW9O3OASdtJ/ghmUvAFUCh7jRQ8Vzrsuda8bYpCjd0DjgeoctA3hijSNrywDywK6J7C4zbTvBowz3IZDL4vl8IZzAAfAHSxpi1hkgngVtA40bfAYq2E6w0NYj8ph+YBMrGmMfNNMf2G25cTI6Fv47G2wNGbSd4bnp1GIEZYCxCfwYGTC9PfDibGWBvGIRcTw0A/HuWWTfsAz7ZTuDzH+3wC3yX6sioK4amAAAAAElFTkSuQmCC') !important;
			}
			
			@keyframes preloaderspinoff {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(-360deg);
				}
			}
			@-webkit-keyframes preloaderspinoff {
				0% {
					-webkit-transform: rotate(0deg);
				}
				100% {
					-webkit-transform: rotate(-360deg);
				}
			}
			@-moz-keyframes preloaderspinoff {
				0% {
					-moz-transform: rotate(0deg);
				}
				100% {
					-moz-transform: rotate(-360deg);
				}
			}
			@-o-keyframes preloaderspinoff {
				0% {
					-o-transform: rotate(0deg);
				}
				100% {
					-o-transform: rotate(-360deg);
				}
			}
			@-ms-keyframes preloaderspinoff {
				0% {
					-ms-transform: rotate(0deg);
				}
				100% {
					-ms-transform: rotate(-360deg);
				}
			}
			
		}
		
	}
}

#movetotop {
	position: fixed;
	opacity: 0.6;
	background: #333;
	color: #fff;
	width: 34px;
	height: 34px;
	bottom: 15px;
	right: 30px;
	border-radius: 50%;
	text-align: center;
	font-size: 25px;
	line-height: 34px;
	cursor: pointer;
	z-index: 10;
	border: 1px solid #fff;
	
	&:hover {
		opacity: 0.8;
	}
	
	@media(max-width: 768px) {
		right: 8px;
	}
}

#toastcontainer {
	position: fixed;
	top: 10px;
	width: 100%;
	z-index: 3000;
	
	.toast {
		
		&.topscreen {
			width: 95%;
			max-width: inherit;
			margin:0px auto;
			
			.toast-body {
				text-align: center;
				max-width: 350px;
				margin:0px auto;
			}
		}
		
	}
	
	.toastbox {
		position: absolute;
		top: 0;
		right: 0;
	}
}

#pagenotfound {
	height: 700px;
	
	@media(min-width: 400px) {
		height: 800px;
	}
	
	@media(min-width: 500px) {
		height: 1000px;
	}
	
	h2 {
		position: absolute; font-size: 18vw; color: #ccd0d4; left: 50%; transform: translate(-50%, 0); z-index:1;
	}
	
	img.napoleon {
		position: absolute; top: 0px; left: 50%; transform: translate(-50%, 15%); z-index:2;
	}
}

#lotActionSuccessWindow {
	.header {
		line-height:40px;
		
		.icon {
			font-size: 40px;
		}
	}
	
	.progress {
		height: 2px;
	}
	
	.lot {
		
		.name {
			margin: 10px;
			height: 36px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		
	}
}


#lotssidebar {top:0; right: -300px; height: 100%; z-index:1100; color: #fff;}
#lotssidebar.in {right: 0; -webkit-animation: lots-slide-in-right 0.5s both; animation: lots-slide-in-right 0.5s both; }
#lotssidebar.out {-webkit-animation: lots-slide-in-left 0.5s both; animation: lots-slide-in-left 0.5s both;}
#lotssidebar .btn {padding:6px; text-align:center;}
#lotssidebar .sidebar-navbar {width: 40px;}
#lotssidebar .sidebar-content {width: 300px;}
#lotssidebar .sidebar-content h4 {text-align: left; width: 100%; padding: 15px 0 0px; }
#lotssidebar .sidebar-content h4 span {padding-right: 20px;}
#lotssidebar .sidebar-content .row {margin-bottom:10px;}
#lotssidebar .sidebar-content .logo .box {background:#fafafa; text-align:center; font-size:12px; margin-bottom:5px;}
#lotssidebar .sidebar-content .logo .box img {height:40px; padding-top:5px;}
#lotssidebar .sidebar-content .logo .box p {color:#000; margin-bottom:0px;}
#lotssidebar .sidebar-content > div {display: none;}
#lotssidebar .sidebar-content .lot {margin-bottom:6px;}
#lotssidebar .sidebar-content .lot:nth-child(even) {padding-right:3px;}
#lotssidebar .sidebar-content .lot:nth-child(odd) {padding-left:3px;}
#lotssidebar .sidebar-content div.active {display:block;}
#lotssidebar .lots_content {height:100%; padding-bottom:100px;}
#lotssidebar .lots_content .lots {padding-right:5px; overflow-y:auto; overflow-x:hidden; height:100%; flex-wrap:wrap;}
#lotssidebar .hide_button {cursor: pointer;}
@-webkit-keyframes lots-slide-in-right {
	0% {
		-webkit-transform: translateX(300px);
		transform: translateX(300px);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes lots-slide-in-right {
	0% {
		-webkit-transform: translateX(300px);
		transform: translateX(300px);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

@-webkit-keyframes lots-slide-in-left {
	0% {
		-webkit-transform: translateX(-300px);
		transform: translateX(-300px);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes lots-slide-in-left {
	0% {
		-webkit-transform: translateX(-300px);
		transform: translateX(-300px);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

.spin {
	animation-name: spin;
	animation-duration: 2s;
	animation-timing-function: linear;
	animation-delay: 0s;
	animation-iteration-count: infinite;
	animation-direction: normal;
	animation-fill-mode: none;
	animation-play-state: running;
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}


.scale-in-center{-webkit-animation:scale-in-center .5s cubic-bezier(.25,.46,.45,.94) both;animation:scale-in-center .5s cubic-bezier(.25,.46,.45,.94) both}
@keyframes scale-in-center{0%{transform:scale(0);opacity:1}100%{transform:scale(1);transform:scale(1);opacity:1}}
@-webkit-keyframes scale-in-center{0%{-webkit-transform:scale(0);transform:scale(0);opacity:1}100%{-webkit-transform:scale(1);transform:scale(1);opacity:1}}
@-o-keyframes scale-in-center{0%{-o-transform:scale(0);transform:scale(0);opacity:1}100%{-o-transform:scale(1);transform:scale(1);opacity:1}}
@-moz-keyframes scale-in-center{0%{-moz-transform:scale(0);transform:scale(0);opacity:1}100%{-moz-transform:scale(1);transform:scale(1);opacity:1}}
@-ms-keyframes scale-in-center{0%{-ms-transform:scale(0);transform:scale(0);opacity:1}100%{-ms-transform:scale(1);transform:scale(1);opacity:1}}
.scale-out-center{-webkit-animation:scale-out-center .5s cubic-bezier(.55,.085,.68,.53) both;animation:scale-out-center .5s cubic-bezier(.55,.085,.68,.53) both}
@keyframes scale-out-center{0%{transform:scale(1);transform:scale(1);opacity:1}100%{transform:scale(0);opacity:1}}
@-webkit-keyframes scale-out-center{0%{-webkit-transform:scale(1);transform:scale(1);opacity:1}100%{-webkit-transform:scale(0);transform:scale(0);opacity:1}}
@-o-keyframes scale-out-center{0%{-o-transform:scale(1);transform:scale(1);opacity:1}100%{-o-transform:scale(0);transform:scale(0);opacity:1}}
@-moz-keyframes scale-out-center{0%{-moz-transform:scale(1);transform:scale(1);opacity:1}100%{-moz-transform:scale(0);transform:scale(0);opacity:1}}
@-ms-keyframes scale-out-center{0%{-ms-transform:scale(1);transform:scale(1);opacity:1}100%{-ms-transform:scale(0);transform:scale(0);opacity:1}}


.dropzone {
	
	border-radius: 8px;
	
	&:hover {
		background: #ebffed;
	}
	
	&.dz-drag-hover {
		background: #ebffed;
	}
	
	.dz-preview {
		
		&.dz-image-preview {
			background: transparent;

			.dz-image img {
				width: 100% !important;
				height: 100% !important;
				object-fit: cover;
			}
			
			.dz-progress {
				display: none;
			}
		}
	}
	
}


#toast-container .toast {background-color: transparent;}
#toast-container {left:0px; right:0px; top:0px; bottom:0px; height:0px; text-align:center;}
#toast-container.toast-top-center > div {opacity:0.95; -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=95); filter: alpha(opacity=95); padding: 20px 20px 20px 50px; margin-bottom:5px;}

.datepicker {
	
	border-radius: 12px !important;
	
	.disabled {
		color: #ddd!important;
	}
	
	.table-condensed {
		border-collapse: separate !important;
		border-spacing: 15px 10px !important;
		font-size: 14px;
		
		@media (max-width: 400px) {
			border-spacing: 6px 1px !important;
			font-size: 12px;
		}
		@media (max-width:430px) and (min-width:401px) {
			border-spacing: 6px 1px !important;
			font-size: 12px;
		}
	}
	
	.datepicker-months {
		.table-condensed {
			border-spacing: 28px 10px !important;
		}
		.month {
			width: 54px !important;
			height: 54px !important;
		}
		.active {
			background: #fff !important;
			border: 1px solid #f14c10 !important;
			border-radius: 50% !important;
			color: #000 !important;
		}
		.active.focused {
			background: #f14c10 !important;
			color: #fff !important;
		}
		.month:hover {
			background: #f14c10 !important;
			border-radius: 50% !important;
			color: #fff !important;
		}
	}
	.datepicker-years {
		.table-condensed {
			border-spacing: 28px 10px !important;
		}
		.year {
			width: 54px !important;
			height: 54px !important;
		}
		.active {
			background: #fff !important;
			border: 1px solid #f14c10 !important;
			border-radius: 50% !important;
			color: #000 !important;
		}
		.active.focused {
			background: #f14c10 !important;
			color: #fff !important;
		}
		.year:hover {
			background: #f14c10 !important;
			border-radius: 50% !important;
			color: #fff !important;
		}
	}
	.datepicker-days {
		.active {
			background: #f14c10 !important;
			border-radius: 50% !important;
			color: #fff !important;
		}
	}
	
	.dow {
		color: #6c757d !important;
	}
	
	.day {
		width: 36px !important;
		height: 36px !important;
		
		@media (max-width: 400px) {
			width: 30px!important;
			height: 30px!important;
		}

		@media (max-width:430px) and (min-width:401px) {
			width: 30px!important;
			height: 30px!important;
		}
	}
	
	.day:hover {
		background: #f14c10 !important;
		border-radius: 50% !important;
		color: #fff !important;
	}
	
	.next, .prev {
		color: #f14c10 !important;
	}
	
	.today {
		background: #fff !important;
		border: 1px solid #f14c10 !important;
		border-radius: 50% !important;
		color: #000 !important;
	}
	
	.focused, .active {
		background: #f14c10 !important;
		border-radius: 50% !important;
		color: #fff !important;
	}
}



#eventspage {

	.itemslist {
		
		.item {
			
			.card-image {
				max-height: 300px;
			}
			
			.name {
				height: 40px;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				margin-bottom: 15px;
			}
			
			.place {
				height: 40px;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
			}
			
		}
	}
	
}
